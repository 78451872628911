.exam {
  padding: 30px;
}

.question {
  background-color: grey;
  margin: 50px;
}

.btn {
  display:flex;
  justify-content:center;
}